import pimg1 from '../images/case/img-1.jpg'
import pimg2 from '../images/case/img-2.jpg'
import pimg3 from '../images/case/img-3.jpg'
import pimg4 from '../images/case/img-4.jpg'
import pimg5 from '../images/case/img-5.jpg'
import pimg6 from '../images/case/img-6.jpg'

import psimg1 from '../images/case/img-16.jpg'
import psimg2 from '../images/case/img-14.jpg'
import psimg3 from '../images/case/img-15.jpg'
import psimg4 from '../images/case/img-17.jpg'
import psimg5 from '../images/case/img-18.jpg'
import psimg6 from '../images/case/img-19.jpg'

const Projects = [
   {
      Id: '1',
      pImg: pimg1,
      psImg: psimg1,
      title: 'Business Growth System',
      slug: 'Business-Growth-System',
      subTitle: 'Consulting',
   },
   {
      Id: '2',
      pImg: pimg2,
      psImg: psimg2,
      title: 'Agile Adaptation to Change',
      slug: 'Agile-Adaptation-to-Change',
      subTitle: 'Maketing',
   },
   {
      Id: '3',
      pImg: pimg3,
      psImg: psimg3,
      title: 'Supply Chain Optimization',
      slug: 'Supply-Chain-Optimization',
      subTitle: 'Business',
   },
   {
      Id: '4',
      pImg: pimg4,
      psImg: psimg4,
      title: 'Environmental and Social Responsibility',
      slug: 'Environmental-and-Social-Responsibility',
      subTitle: 'Consulting',
   },
   {
      Id: '5',
      pImg: pimg5,
      psImg: psimg5,
      title: 'Enhanced Security and Compliance',
      slug: 'Enhanced-Security-and-Compliance',
      subTitle: 'Consulting',
   },
   {
      Id: '6',
      pImg: pimg6,
      psImg: psimg6,
      title: 'Investment planning',
      slug: 'Investment-plannings',
      subTitle: 'Maketing',
   },
]

export default Projects;