import React from 'react'
import pImg1 from '../../images/case/img-6.jpg'
import pImg2 from '../../images/case/img-7.jpg'
import pImg3 from '../../images/case/img-8.jpg'
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import backShape from '../../images/case/left-bg.png'
import cimg from '../../images/case/bottom-shape.png'
import { Link } from 'react-router-dom'

const Portfolios = [
    {
        Pimg: pImg1,
    },
    {
        Pimg: pImg2,
    },
    {
        Pimg: pImg3,
    },

]


const ProjectSection2 = (props) => {

    const [open, setOpen] = React.useState(false);


    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }


    return (

        <section className="case-section-s2">
            <div className="case-wrap">
                <div className="case-left-wrap">
                    <div className="case-left" style={{ backgroundImage: `url(${backShape})` }}>
                        <div className="case-left-text">
                            <div className="heading-title">
                                <small>Case Studies</small>
                                <h2>Learn Something About Our <span>Case Studies</span></h2>
                            </div>
                            <div className="funfact">
                                <small>Complete Our Work</small>
                                <h2><span className="odometer" data-count="1298">1298</span>+</h2>
                            </div>
                            <p>Transforming ideas into user-friendly and visually appealing websites Accounting and
                                Bookkeeping: Services related. Tempor nec rutrum in, gravida eu ipsum</p>
                            <Link onClick={ClickHandler} to="/service" className="btn-style-1">All Case Studies</Link>
                        </div>
                        <div className="bottom-shape">
                            <img src={cimg} alt="" />
                        </div>
                    </div>
                </div>
                <div className="case-right">
                    {Portfolios.map((image, i) => (
                        <div className="case-right-item" key={i}>
                            <img src={image.Pimg} alt="" className="img img-responsive" />
                            <i className="icon-17" onClick={() => setOpen(true)}></i>
                        </div>
                    ))}
                </div>
            </div>
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={[
                    { src: pImg1 },
                    { src: pImg2 },
                    { src: pImg3 },
                ]}
            />
        </section>
    )
}

export default ProjectSection2;