import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle.js'
import Navbar2 from '../../components/Navbar2/Navbar2.js';
import Footer from '../../components/footer/Footer.js';
import Scrollbar from '../../components/scrollbar/scrollbar.js'
import Bg from '../../images/page-title/bg-4.jpg'
import BlogList from '../../components/BlogList/BlogList.js';
import CtaSection from '../../components/CtaSection/CtaSection.js';

const BlogPageFullwidth = () => {
    return (
        <Fragment>
            <div className='page-wrapper'>
                <Navbar2 hclass={'header-style-2'} />
                <PageTitle pageTitle={'Latest News'} pagesub={'Blog'} Bg={Bg} />
                <BlogList blLeft={'d-none'} blRight={'col-lg-10 offset-lg-1'} />
                <CtaSection />
                <Footer />
                <Scrollbar />
            </div>
        </Fragment>
    )
};
export default BlogPageFullwidth;