import React from 'react'
import { Link } from 'react-router-dom'
import simg from '../../images/benefit/1.jpg'
import simg2 from '../../images/benefit/2.jpg'
import simg3 from '../../images/benefit/3.jpg'
import simg4 from '../../images/benefit/4.jpg'
import backShape from '../../images/benefit/pattern.png'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const Services = [
    {
        Id: '01',
        sImg: simg,
        sTitle: 'Business',
        description: 'Proin efficitur, mauris vel condimentum pulvinar.',
        icon: 'icon-11',
    },
    {
        Id: '02',
        sImg: simg2,
        sTitle: 'Trading',
        description: 'Proin efficitur, mauris vel condimentum pulvinar.',
        icon: 'icon-10',
    },
    {
        Id: '09',
        sImg: simg3,
        sTitle: 'Investor',
        description: 'Proin efficitur, mauris vel condimentum pulvinar.',
        icon: 'icon-14',
    },
    {
        Id: '08',
        sImg: simg4,
        sTitle: 'Accounts',
        description: 'Proin efficitur, mauris vel condimentum pulvinar.',
        icon: 'icon-25',
    },
]


const BenefitSection = (props) => {
    return (
        <section className="benefit-section separator-padding"
            style={{ backgroundImage: `url(${backShape})` }}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-7 col-12">
                        <div className="heading-title-s2">
                            <small>How to Benefit</small>
                            <h2>Provide Customers & Unique <span>Solutions</span></h2>
                        </div>
                    </div>
                </div>
                <div className="benefit-wrap">
                    <div className="row">
                        {Services.map((service, srv) => (
                            <div className="col col-xl-3 col-lg-3 col-sm-6 col-12" key={srv}>
                                <div className="benefit-item">
                                    <div className="benefit-img">
                                        <img src={service.sImg} alt="" />
                                    </div>
                                    <div className="benefit-top">
                                        <div className="icon">
                                            <i className={service.icon}></i>
                                        </div>
                                        <div className="benefit-text">
                                            <h3>{service.sTitle}</h3>
                                            <p>{service.description}</p>
                                        </div>
                                    </div>
                                    <div className="bottom-text">
                                        <h3><Link onClick={ClickHandler} to="/service">{service.sTitle}</Link></h3>
                                        <Link onClick={ClickHandler} to="/service" className="right-btn"><i className="icon-45"></i></Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BenefitSection;