import simg from '../images/service/img-2.jpg'
import simg2 from '../images/service/img-5.jpg'
import simg3 from '../images/service/img-4.jpg'
import simg4 from '../images/service/img-1.jpg'
import simg5 from '../images/service/img-3.jpg'
import simg6 from '../images/service/img-6.jpg'


const Services = [
    {
        Id: '01',
        sImg:simg,
        sTitle:'Website Development',
        slug:'Website-Development',
        description:'Implementing cost-effective measures to streamline operations, optimize processes.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'icon-13',
    },
    {
        Id: '02',
        sImg:simg2,
        sTitle:'Digital Strategy',
        slug:'Digital-Strategy',
        description:'Implementing cost-effective measures to streamline operations, optimize processes.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'icon-16',
    },
    {
        Id: '03',
        sImg:simg3,
        sTitle: 'Consultancy & Advice',
        slug:'Consultancy-&-Advice',
        description:'Implementing cost-effective measures to streamline operations, optimize processes.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'icon-14',
    },
    {
        Id: '04',
        sImg:simg4,
        sTitle: 'Technologic Services',
        slug:'Technologic-Services',
        description:'Implementing cost-effective measures to streamline operations, optimize processes.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'icon-25',
    },
    {
        Id: '05',
        sImg:simg5,
        sTitle:'Audit & Evalution',
        slug:'Audit-&-Evalution',
        description:'Implementing cost-effective measures to streamline operations, optimize processes.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'icon-24',
    },
    {
        Id: '06',
        sImg:simg6,
        sTitle:'Strategy & Planning',
        slug:'Strategy-&-Planning',
        description:'Implementing cost-effective measures to streamline operations, optimize processes.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'icon-15',
    }
]    

export default Services;