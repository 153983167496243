import React from 'react'
import { Link } from 'react-router-dom'
import backShape from '../../images/about/tshape.png'
import abimg from '../../images/about/about-img3.jpg'
import abimg2 from '../../images/about/about-img4.jpg'
import abimg3 from '../../images/about/about-img5.jpg'
import shape from '../../images/about/radial.png'
import VideoModal from '../ModalVideo/VideoModal'

const About2 = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="about-section-s2">
            <div className="back-shape"
                style={{ backgroundImage: `url(${backShape})` }}>
            </div>
            <div className="container">
                <div className="about-wrap">
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-12 col-12">
                            <div className="about-top-left">
                                <div className="about-top-text">
                                    <Link onClick={ClickHandler} to="/about" className="btn-style-1">Get A Quote</Link>
                                    <div className="about-top-right">
                                        <p>25+ Years</p>
                                        <span>Experienced</span>
                                    </div>
                                </div>
                                <h2>Ready To Give A New Business Look</h2>
                                <p>Transforming ideas into user-friendly and visually appealing websites Accounting and
                                    Bookkeeping.</p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                            <div className="about-text">
                                <div className="about-icon-wrap">
                                    <i className="icon-26"></i>
                                    <div className="about-icon-text">
                                        <h4>298+</h4>
                                        <small>Awards Won</small>
                                    </div>
                                </div>
                                <span>Transforming ideas into user-friendly and visually appealing websites Accounting
                                    and Bookkeeping.</span>
                                <p>Transforming ideas into user-friendly and visually appealing websites Accounting and
                                    Bookkeeping Services related to <b>financial record-keeping.</b></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-bottom-wrap">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-12">
                            <div className="about-bottom-left">
                                <div className="heading-title">
                                    <small>About Company</small>
                                    <h2>Our Main Gola To Modern <span>Business Solutions</span></h2>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-12">
                                        <div className="about-bottom-left-img">
                                            <img src={abimg} alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <div className="about-bottom-left-text">
                                            <h2>Digital marketing and social management</h2>
                                            <p>Transforming ideas into user-friendly and visually appealing websites
                                                Accounting and Bookkeeping: Services related.</p>
                                            <ul>
                                                <li><i className="icon-21"></i>The Art and Science of Building</li>
                                                <li><i className="icon-21"></i>Mastering the Construction Process</li>
                                                <li><i className="icon-21"></i>Green Construction Practices</li>
                                            </ul>
                                            <div className="icon-wraps">
                                                <div className="icon-wrap">
                                                    <div className="icon">
                                                        <i className="icon-25"></i>
                                                    </div>
                                                    <div className="icon-text">
                                                        <h4>Website Development</h4>
                                                    </div>
                                                </div>
                                                <div className="icon-wrap">
                                                    <div className="icon">
                                                        <i className="icon-24"></i>
                                                    </div>
                                                    <div className="icon-text">
                                                        <h4>Social Media Management</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link onClick={ClickHandler} to="/about">More About Us</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="about-bottom-right">
                                <div className="about-bottom-right-img">
                                    <img src={abimg2} alt=""/>
                                        <div className="progress-wrap">
                                            <span>Success Rate</span>
                                            <div className="progress blue">
                                                <span className="progress-left">
                                                    <span className="progress-bar"></span>
                                                </span>
                                                <span className="progress-right">
                                                    <span className="progress-bar"></span>
                                                </span>
                                                <div className="progress-value">95%</div>
                                            </div>
                                        </div>
                                </div>
                                <div className="about-bottom-right-img">
                                    <img src={abimg3} alt=""/>
                                        <div className="video-btn-wrap">
                                            <VideoModal />
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ab-shape">
                        <img src={shape} alt=""/>
                    </div>
                </div>
            </div>
            <div className="top-shape">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 720 92" fill="none">
                    <path d="M0 0L720 91.5V2L0 0Z" />
                </svg>
            </div>
        </section>
    )
}

export default About2;