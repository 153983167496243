import React, {Fragment} from 'react';
import Navbar2 from '../../components/Navbar2/Navbar2';
import PageTitle from '../../components/pagetitle/PageTitle'
import Error from '../../components/404/404'
import Bg from '../../images/page-title/bg-8.jpg'
import CtaSection from '../../components/CtaSection/CtaSection';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';

const ErrorPage =() => {
    return(
        <Fragment>
            <div className='page-wrapper'>
                <Navbar2 hclass={'header-style-2'} />
                <PageTitle pageTitle={'Error 404'} pagesub={'404'} Bg={Bg} />
                <Error/>
                <CtaSection/>
                <Footer />
                <Scrollbar />
            </div>
        </Fragment>
    )
};
export default ErrorPage;

