import timg1 from '../images/team/img-1.png'
import timg2 from '../images/team/img-2.png'
import timg3 from '../images/team/img-3.png'
import timg4 from '../images/team/img-4.png'
import timg5 from '../images/team/img-5.png'
import timg6 from '../images/team/img-6.png'


const Teams = [
    {
        Id: '1',
        tImg:timg1,
        name:'Jone Willsone',
        slug:'Jone-Willsone',
        title:'Web Designer',
    },
    {
        Id: '2',
        tImg:timg2,
        name:'Wade Warren',
        slug:'Wade-Warren',
        title:'Creative Design',
    },
    {
        Id: '3',
        tImg:timg3,
        name:'Esther Howard',
        slug:'Esther-Howard',
        title:'Ux Designer',
    },
    {
        Id: '4',
        tImg:timg4,
        name:'Ronal Aarker',
        slug:'Ronal-Aarker',
        title:'Web Designer',
    },
    {
        Id: '5',
        tImg:timg5,
        name:'Jarne Browen',
        slug:'Jarne-Browen',
        title:'Web Designer',
    },
    {
        Id: '6',
        tImg:timg6,
        name:'Methorad',
        slug:'Methorad',
        title:'Ux Designer',
    },
]

export default Teams;