// images
import blogImg1 from "../images/blog/img-1.jpg";
import blogImg2 from "../images/blog/img-2.jpg";
import blogImg3 from "../images/blog/img-3.jpg";
import blogImg4 from "../images/blog/img-4.jpg";
import blogImg5 from "../images/blog/img-5.jpg";
import blogImg6 from "../images/blog/img-6.jpg";
import blogImg7 from "../images/blog/img-7.jpg";
import blogImg8 from "../images/blog/img-8.jpg";
import blogImg9 from "../images/blog/img-9.jpg";
import blogImg10 from "../images/blog/img-10.jpg";
import blogImg11 from "../images/blog/img-11.jpg";
import blogImg12 from "../images/blog/img-12.jpg";

import blogSingleImg1 from "../images/blog/img-13.jpg";
import blogSingleImg2 from "../images/blog/img-14.jpg";
import blogSingleImg3 from "../images/blog/img-15.jpg";
import blogSingleImg4 from "../images/blog/img-16.jpg";
import blogSingleImg5 from "../images/blog/img-17.jpg";
import blogSingleImg6 from "../images/blog/img-18.jpg";



const blogs = [ 
    {
        id: '1',
        title: 'Unlocking the Right Fit: 5 Interview Tips for Spotting',
        slug: 'Unlocking-the-Right-Fit-5-Interview-Tips-for-Spotting',
        screens: blogImg1,
        blImg: blogImg3,
        description: 'Consectetur adipiscing elit. Purusout phasellus malesuada lectus.',
        author: 'Marilou Kelleher',
        authorTitle:'Sineor Consultant',
        create_at: 'Feb 16, 2024',
        create_day: '16',
        create_month: 'Feb',
        create_year: '2024',
        blogSingleImg:blogSingleImg1, 
        comment:'35',
        blClass:'format-standard-image',
    },
    {
        id: '2',
        title: 'Over ride the digital divide with additional',
        slug: 'Over-ride-the-digital-divide-with-additional',
        screens: blogImg2,
        blImg: blogImg4,
        description: 'Consectetur adipiscing elit. Purusout phasellus malesuada lectus.',
        author: 'Konal Biry',
        authorTitle:'Creative Director',
        create_at: 'March 17, 2024',
        create_day: '17',
        create_month: 'March',
        create_year: '2024',
        blogSingleImg:blogSingleImg2, 
        comment:'80',
        blClass:'format-standard-image',
    },
    {
        id: '3',
        title: 'The Most Popular New Top Business Design',
        slug: 'The-Most-Popular-New-Top-Business-Design',
        screens: blogImg9,
        blImg: blogImg5,
        description: 'Consectetur adipiscing elit. Purusout phasellus malesuada lectus.',
        author: 'Aliza Anny',
        authorTitle:'Art Director',
        create_at: 'Jan 18, 2024',
        create_day: '18',
        create_month: 'Jan',
        create_year: '2024',
        blogSingleImg:blogSingleImg3,
        comment:'95',
        blClass:'format-video',
    },
    {
        id: '4',
        title: 'Our business thrives to contribute global',
        slug: 'Our-business-thrives-to-contribute-global',
        screens: blogImg10,
        blImg: blogImg6,
        description: 'Consectetur adipiscing elit. Purusout phasellus malesuada lectus.',
        author: 'Marilou Kelleher',
        authorTitle:'Sineor Consultant',
        create_at: 'Feb 16, 2024',
        create_day: '16',
        create_month: 'Feb',
        create_year: '2024',
        blogSingleImg:blogSingleImg4, 
        comment:'35',
        blClass:'format-standard-image',
    },
    {
        id: '5',
        title: 'Finding a Way to Separate ‘Work’ Business',
        slug: 'Finding-a-Way-to-Separate-‘Work’-Business.',
        screens: blogImg11,
        blImg: blogImg7,
        description: 'Consectetur adipiscing elit. Purusout phasellus malesuada lectus.',
        author: 'Konal Biry',
        authorTitle:'Creative Director',
        create_at: 'March 17, 2024',
        create_day: '17',
        create_month: 'March',
        create_year: '2024',
        blogSingleImg:blogSingleImg5, 
        comment:'80',
        blClass:'format-standard-image',
    },
    {
        id: '6',
        title: 'How to Manage Business’s Online Reputation',
        slug: 'How-to-Manage-Business’s-Online-Reputation',
        screens: blogImg12,
        blImg: blogImg8,
        description: 'Consectetur adipiscing elit. Purusout phasellus malesuada lectus.',
        author: 'Aliza Anny',
        authorTitle:'Art Director',
        create_at: 'Jan 18, 2024',
        create_day: '18',
        create_month: 'Jan',
        create_year: '2024',
        blogSingleImg:blogSingleImg6,
        comment:'95',
        blClass:'format-video',
    },
];
export default blogs;