import React from "react";
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Link } from 'react-router-dom'
import hero1 from '../../images/hero/slide-1.jpg'
import hero2 from '../../images/hero/slide-2.jpg'
import shape1 from '../../images/hero/vector4.png'
import shape2 from '../../images/hero/customer-1.png'
import shape3 from '../../images/hero/customer-2.png'



const Hero2 = () => {
    return (

        <section className="hero-slider">
            <Swiper
                // install Swiper modules
                modules={[Navigation, A11y]}
                spaceBetween={0}
                slidesPerView={1}
                loop={true}
                speed={1800}
                parallax={true}
                navigation
            >
                <SwiperSlide>
                    <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero1})` }}>
                        <div className="container">
                            <div className="slide-content">
                                <div data-swiper-parallax="300" className="slide-title">
                                    <h2>Your Business With Consl
                                        <span>Agency
                                            <i><img src={shape1} alt="" /></i></span>
                                    </h2>
                                    <div className="customer-review">
                                        <div className="customer-review-img">
                                            <img src={shape2} alt="" />
                                            <img src={shape3} alt="" />
                                        </div>
                                        <div className="customer-review-text">
                                            <span>Customers Satisfied </span>
                                            <ul>
                                                <li><i className="icon-27"></i></li>
                                                <li>4.8 (1.5k Reviews)</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div data-swiper-parallax="400" className="slide-text">
                                    <p>Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur
                                        ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id
                                        massa ante. Suspendisse </p>
                                </div>
                                <div className="clearfix"></div>
                                <div data-swiper-parallax="500" className="slide-btns">
                                    <Link to="/about" className="btn-style-1">Get A Quote</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero2})` }}>
                        <div className="container">
                            <div className="slide-content">
                                <div data-swiper-parallax="300" className="slide-title">
                                    <h2>Your Business With Consl
                                        <span>Agency
                                            <i><img src={shape1} alt="" /></i></span>
                                    </h2>
                                    <div className="customer-review">
                                        <div className="customer-review-img">
                                            <img src={shape2} alt="" />
                                            <img src={shape3} alt="" />
                                        </div>
                                        <div className="customer-review-text">
                                            <span>Customers Satisfied </span>
                                            <ul>
                                                <li><i className="icon-27"></i></li>
                                                <li>4.8 (1.5k Reviews)</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div data-swiper-parallax="400" className="slide-text">
                                    <p>Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur
                                        ligula, eget egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id
                                        massa ante. Suspendisse </p>
                                </div>
                                <div className="clearfix"></div>
                                <div data-swiper-parallax="500" className="slide-btns">
                                    <Link to="/about" className="btn-style-1">Get A Quote</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                ...
            </Swiper>
        </section>
    )
}

export default Hero2;