import React from 'react';
import ContactForm from '../ContactFrom/ContactForm'
import cImg1 from '../../images/contact/contact-left.jpg'
import cImg2 from '../../images/contact/right-shape.png'
import shape from '../../images/contact/pattern.png'
import Bg from '../../images/contact/bg-contact-pattern.png'


const ContactSection = () => {

    return (
        <section className="contact-area separator-padding">
            <div className="left-img">
                <img src={cImg1} alt=""/>
                    <div className="shape">
                        <img src={cImg2} alt=""/>
                    </div>
            </div>
            <div className="right-shape"
                style={{ backgroundImage: `url(${Bg})` }}>
            </div>
            <div className="container">
                <div className="contact-area-wrapper">
                    <div className="row">
                        <div className="col col-lg-6 offset-lg-6 col-md-12 col-12">
                            <div className="heading-title">
                                <small>Get In Touch</small>
                                <h2>Get a quote for start <span>new journey</span></h2>
                            </div>
                            <div className="contact-form-area">
                            <ContactForm />
                                <div className="shape"><img src={shape} alt=""/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default ContactSection;
