import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../images/logo.svg'
import logo2 from '../../images/logo-2.png'
import MobileMenu from '../MobileMenu/MobileMenu';
import HeaderTopbar from '../HeaderTopbar/HeaderTopbar';

const Header2 = (props) => {

    const [menuActive, setMenuState] = useState(false);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    return (
        <header className={`header-area ${props.hclass}`}>
            <HeaderTopbar />
            <div id="header-sticky" className="menu-area">
                <div className="container-fluid">
                    <div className="second-menu">
                        <div className="row align-items-center">
                            <div className="col-xl-2 col-lg-3 col-md-7 col-5">
                                <div className="logo">
                                    <Link className='logo-1' onClick={ClickHandler} to="/home"><img src={logo} alt="logo" /></Link>
                                    <Link className='logo-2' onClick={ClickHandler} to="/home"><img src={logo2} alt="logo" /></Link>
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-6 col-md-1 col-1 text-right text-xl-right d-none d-lg-block">
                                <div className="main-menu">
                                    <nav id="mobile-menu">
                                        <ul className="nav">
                                            <li className="has-submenu">
                                                <Link onClick={ClickHandler} to="/home">Home</Link>
                                                <ul className="sub-menu">
                                                    <li><Link onClick={ClickHandler} to="/home">Home Page 01</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/home-2">Home Page 02</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/home-3">Home Page 03</Link></li>
                                                </ul>
                                            </li>
                                            <li><Link onClick={ClickHandler} to="/about">About</Link></li>
                                            <li className="has-submenu"><Link onClick={ClickHandler} to="/about">Pages</Link>
                                                <ul className="sub-menu">
                                                    <li><Link onClick={ClickHandler} to="/about">About</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/team">Team Page</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/team-single/Jone-Willsone">Team Single</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/pricing">Pricing</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/faq">Faq</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/404">404</Link></li>
                                                </ul>
                                            </li>
                                            <li className="has-submenu"><Link onClick={ClickHandler} to="/service">Services</Link>
                                                <ul className="sub-menu">
                                                    <li><Link onClick={ClickHandler} to="/service">Services Style 1</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/service-s2">Services Style 2</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/service-s3">Services Style 3</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/service-single/Website-Development">Service Single</Link></li>
                                                </ul>
                                            </li>
                                            <li className="has-submenu"><Link onClick={ClickHandler} to="/projects">Projects</Link>
                                                <ul className="sub-menu">
                                                    <li><Link onClick={ClickHandler} to="/projects">Projects Style 1</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/projects-s2">Projects Style 2</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/projects-s3">Projects Style 3</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/projects-s4">Projects Style 4</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/project-single/Business-Growth-System">Project Single</Link></li>
                                                </ul>
                                            </li>
                                            <li className="has-submenu">
                                                <Link onClick={ClickHandler} to="/home">Blog</Link>
                                                <ul className="sub-menu">
                                                    <li><Link onClick={ClickHandler} to="/blog">Blog Grid</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/blog-style-2">Blog Grid Style 2</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/blog-right-sidebar">Blog right sidebar</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/blog-left-sidebar">Blog left sidebar</Link></li>
                                                    <li><Link onClick={ClickHandler} to="/blog-fullwidth">Blog fullwidth</Link></li>
                                                    <li className="third-lavel-menu"><Link onClick={ClickHandler} to="/blog-single/The-Most-Popular-New-Top-Business-Design">Blog
                                                        Single</Link>
                                                        <ul className="sub-menu">
                                                            <li><Link onClick={ClickHandler} to="/blog-single/The-Most-Popular-New-Top-Business-Design">Blog single right sidebar</Link>
                                                            </li>
                                                            <li><Link onClick={ClickHandler} to="/blog-single-left-sidebar/The-Most-Popular-New-Top-Business-Design">Blog single left
                                                                sidebar</Link></li>
                                                            <li><Link onClick={ClickHandler} to="/blog-single-fullwidth/The-Most-Popular-New-Top-Business-Design">Blog single
                                                                fullwidth</Link></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li><Link onClick={ClickHandler} to="/contact">Contact</Link></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-4 col-5 text-left">
                                <div className="header-area-right">
                                    <ul>
                                        <li><button className="search-toggle-btn"><i className="icon-23" onClick={() => setMenuState(!menuActive)}></i></button></li>
                                        <li className="header-right-btn">
                                            <Link onClick={ClickHandler} to="/contact" className="btn-style-1">Get A Quote</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-1">
                                <MobileMenu />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`header-search-form ${menuActive ? "active" : ""}`}>
                <button className="close-header-search" onClick={() => setMenuState(!menuActive)}><i className="icon-02"></i></button>
                <form method="post" onSubmit={SubmitHandler}>
                    <div className="form-group">
                        <input type="text" name="search" value="" placeholder="Search Here" required="" />
                        <button type="submit" className="search-btn"><i className="icon-23"></i></button>
                    </div>
                </form>
            </div>
        </header>
    )
}

export default Header2;