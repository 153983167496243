import React from 'react'
import pImg1 from '../../images/case/img-9.jpg'
import pImg2 from '../../images/case/img-10.jpg'
import pImg3 from '../../images/case/img-11.jpg'
import pImg4 from '../../images/case/img-12.jpg'
import pImg5 from '../../images/case/img-20.jpg'
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

const Portfolios = [
    {
        Pimg: pImg1,
    },
    {
        Pimg: pImg2,
    },
    {
        Pimg: pImg3,
    },
    {
        Pimg: pImg4,
    },
    {
        Pimg: pImg5,
    },

]


const ProjectSection3 = (props) => {

    const [open, setOpen] = React.useState(false);


    return (

        <section className="case-section-s4 separator-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-7 col-12">
                        <div className="heading-title-s2 text-center">
                            <small>Case Studies</small>
                            <h2>Learn Something About Our <span>Case Studies</span></h2>
                        </div>
                    </div>
                </div>
                <div className="case-wrap">
                    <div className="case-right">
                        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 3, 900: 3 }}>
                            <Masonry columnsCount={3} gutter="15px">
                                {Portfolios.map((image, i) => (
                                    <div className="case-item" key={i}>
                                        <div className="case-img">
                                            <img src={image.Pimg} alt="" className="img img-responsive" />
                                            <i className="icon-17" onClick={() => setOpen(true)}></i>
                                        </div>
                                    </div>
                                ))}
                            </Masonry>
                        </ResponsiveMasonry>
                    </div>
                </div>
            </div>
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={[
                    { src: pImg1 },
                    { src: pImg2 },
                    { src: pImg3 },
                    { src: pImg4 },
                ]}
            />
        </section>
    )
}

export default ProjectSection3;