import React from "react";
import pimg1 from '../../images/partners/1.png'
import pimg2 from '../../images/partners/2.png'
import pimg3 from '../../images/partners/3.png'
import pimg4 from '../../images/partners/4.png'
import pimg5 from '../../images/partners/5.png'
import pimg6 from '../../images/partners/6.png'
import pimg7 from '../../images/partners/7.png'
import pimg8 from '../../images/partners/8.png'
import pimg9 from '../../images/partners/9.png'

const partners = [
    {
        pImg: pimg1,
    },
    {
        pImg: pimg2,
    },
    {
        pImg: pimg3,
    },
    {
        pImg: pimg4,
    },
    {
        pImg: pimg5,
    },
    {
        pImg: pimg6,
    },
    {
        pImg: pimg7,
    },
    {
        pImg: pimg8,
    },
    {
        pImg: pimg9,
    },
]


const PartnerSection = (props) => {
    return (
        <section className="partner-section">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="partner-left">
                            <div className="heading-title">
                                <small>Trusted Best Company</small>
                                <h2>We Help You Build On Your <span>Past & Future.</span></h2>
                                <p>Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget
                                    egestas magna mi ut arcu.</p>
                                <a href="service.html" className="btn-style-1">More Services</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="partner-wrap">
                            {partners.map((partner, pitem) => (
                                <div className="partner-item" key={pitem}><i><img src={partner.pImg} alt="" /></i></div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PartnerSection;