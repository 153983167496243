import React from "react";
import { Link } from 'react-router-dom'
import Bg from '../../images/choose/bg2.jpg'
import chimg from '../../images/choose/img-1.jpg'
import chimg2 from '../../images/choose/img-2.jpg'

const ChooseSection = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    
    return (
        <section className="choose-section separator-padding" style={{ backgroundImage: `url(${Bg})` }}>
            <div className="container">
                <div className="choose-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-12">
                            <div className="choose-items">
                                <div className="heading-title-s3">
                                    <small>Why Choose Us</small>
                                    <h2>Excellent For Batter Business <span>Solutions</span></h2>
                                </div>
                                <div className="choose-item">
                                    <i className="icon-12"></i>
                                    <h3>Professional Engineers</h3>
                                    <p>Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula,
                                        eget egestas magna mi ut arcu.</p>
                                </div>
                                <div className="choose-item">
                                    <i className="icon-12"></i>
                                    <h3>428+ Successfully Projects</h3>
                                    <p>Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula,
                                        eget egestas magna mi ut arcu.</p>
                                </div>
                                <Link onClick={ClickHandler} to="/contact" className="btn-style-1">Get A
                                    Quote</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="choose-right-wrap">
                <div className="choose-img">
                    <img src={chimg} alt="" />
                    <div className="flot-img"><img src={chimg2} alt="" /></div>
                </div>
            </div>
        </section>
    );
}

export default ChooseSection;